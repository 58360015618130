<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M19 3H5c-1.11 0-2 .89-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-8.92 12.58L11.5 17l5-5l-5-5l-1.42 1.41L12.67 11H3v2h9.67l-2.59 2.58z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiLoginVariant",
};
</script>
