<template>
  <component :is="name" :class="iconClassList" />
</template>

<script setup>
import { computed } from "vue";

import { icon } from "./cva";
import classGen from "../utils/class-gen.js";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: null,
  },
});

const iconClassList = computed(() => {
  return classGen(
    {
      size: props.size,
    },
    icon
  );
});
</script>
