<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24" class="arrow-left">
    <path
      d="M20 11v2H8l5.5 5.5l-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5L8 11h12z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiArrowLeft",
};
</script>

<style scoped>
/* Styles for RTL languages */
:dir(rtl) .arrow-left {
  transform: scaleX(-1) translateY(1px); /* Flips horizontally and moves down */
}

/* Styles for LTR languages (default) */
:dir(ltr) .arrow-left {
  transform: translateY(1px); /* Moves down by 1 pixels without flipping */
}
</style>
