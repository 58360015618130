<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiCheckboxBlankCircleOutline",
};
</script>
