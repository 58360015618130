<template>
  <div class="dark:bg-gray-900">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useApolloLogin } from "@/composables/useApolloLogin";
import { useFetchCustomer } from "@/composables/useFetchCustomer";
import { useCartStore } from "@/store/cart.js";
import cartByMessageHash from "./pages/[biz]/cart/cartByMessageHash.gql";

import biz from "@/graph/biz.gql";
import { useBizStore } from "@/store/index.js";
import { useVepaarStore } from "@/store/store.js";
import { useAuthStore } from "@/store/auth.js"; // Adjust the path as needed

import blacklist from "@/assets/blacklist.js";

const route = useRoute();
const { clients } = useApollo();
const nuxtApp = useNuxtApp();

const query = gql`
  ${biz}
`;

if (import.meta.server) {
  // Client side headers are not accessible

  const headers = useRequestHeaders();
  const config = useRuntimeConfig();

  /**
   * Check if store is opened under vepaar.store/<store-name>
   * and has a custom domain already set.
   * If so, redirect user to their custom domain.
   *
   * We need to check this first as on custom domain also, same code will be executed
   * and in that case, route.params.biz will be not set.
   *
   * Vepaar Custom Domain Opened under Vepaar Path:
   * Not Available: req.headers['user-custom-domain']
   * Available: route.params.biz
   *
   * Vepaar Subdomain Only:
   * Available: route.params.biz
   * Unavailable: req.headers['user-custom-domain']
   *
   * Vepaar Custom Domain:
   * Available: req.headers['user-custom-domain']
   * Unavailable: route.params.biz
   *
   */

  /**
   * Memory leak happens if this logic written in plugin
   * biz query hit must be done on app instance since nuxt plugin is not separating app instance
   *  for individual request, resulting into wrong store being loaded for user
   */
  const vepaarSubdomain = route.params.biz?.toString().trim();
  const customDomainInHeader = headers["user-custom-domain"];

  const subdomain = customDomainInHeader || vepaarSubdomain;
  const variables = { subdomain };

  //  If subdomain is not available then redirecting to Web

  if (!subdomain) {
    navigateTo(config.public.WEBSITE_URL, {
      external: true,
    });
  }

  try {
    const {
      biz: {
        desc,
        profilePicture,
        address,
        otherLink,
        socialLink,
        pages,
        stores,
        cover,
        mobiles,
        language,
        webIntegrations,
        customDomain,
        credits,
        url,
      },
      metaData: { storeTheme },
    } = await useAsyncQuery(query, variables)
      .then(({ data }) => {
        /**
         * If subdomain is from blacklist then redirect to Vepaar web.
         */

        if (blacklist.includes(subdomain)) {
          navigateTo(config.public.WEBSITE_URL, {
            external: true,
          });
        } else if (!data.value?.biz) {
          // Just passed this string to throw an error so that catch block handles it.
          throw createError({
            statusCode: 404,
            statusMessage: "STORE_NOT_FOUND",
          });
        } else {
          return data.value;
        }
      })
      .catch(() => {
        throw createError({
          statusCode: 404,
          statusMessage: "STORE_NOT_FOUND",
        });
      });

    /**
     * If subdomain is got from the nuxt dynamic path
     * and customDomain is also available,
     * that means user has opened the store under vepaar.store/subdomain path
     * We should redirect user to their custom domain.
     */

    if (
      customDomain &&
      vepaarSubdomain &&
      import.meta.env.MODE === "production"
    ) {
      const redirectTo = `https://${customDomain}?utm_source=store&utm_medium=redirect_to_custom_domain&utm_campaign=vepaar_branding`;

      /**
       * 302: Temporary Redirect
       * Permenent redirect is being cached
       */

      navigateTo(redirectTo, { redirectCode: 302, external: true });
    } else {
      const activeMobiles = mobiles
        .filter((item) => item.status === "active")
        .map(
          ({
            name,
            mobileNumber,
            designation,
            profilePicture,
            isAvailableForWhatsappSupport,
            isAvailableForCallSupport,
            isAvailable,
          }) => ({
            name:
              name ||
              mobileNumber.internationalFormat ||
              mobileNumber.numberWithCallingCode,
            mobileNumber,
            designation,
            profile: profilePicture,
            whatsappSupport: isAvailableForWhatsappSupport,
            callSupport: isAvailableForCallSupport,
            isAvailable,
          })
        );

      // Set Locale
      // It should be only called once when the app is initialized
      const localeCookie = nuxtApp.$i18n.getLocaleCookie();
      const languageWithUserLocaleFallback =
        language || nuxtApp.$i18n.getBrowserLocale();

      if (!localeCookie) {
        nuxtApp.$i18n.setLocale(languageWithUserLocaleFallback);
      }

      // Store the data in the Biz store
      const bizStore = useBizStore();
      const vepaarStore = useVepaarStore();
      const authStore = useAuthStore();

      bizStore.subdomain = subdomain;
      bizStore.name = stores.length > 0 ? stores[0].name : "";
      bizStore.desc = desc;
      bizStore.language = language;
      bizStore.logo = profilePicture?.size._200x200;
      bizStore.cover = cover?.size._1600x900;
      bizStore.address = address;
      bizStore.otherLink = otherLink;
      bizStore.socialLink = socialLink;
      bizStore.pages = pages;
      bizStore.isCustomDomain = Boolean(customDomain);
      bizStore.mobiles = activeMobiles;
      bizStore.callingCode =
        stores.length > 0 ? stores[0].country?.callingCode : null;
      bizStore.theme = stores.length > 0 ? stores[0].theme : storeTheme;
      bizStore.webIntegrations = webIntegrations;
      bizStore.customCodes = stores.length > 0 ? stores[0].customCodes : [];
      bizStore.credits = credits;
      bizStore.url = url;
      bizStore.countryCode = stores.length > 0 ? stores[0].country?.iso : null;
      bizStore.productPriceDisplay = stores[0].productPriceDisplay;

      if (stores && stores[0]) {
        const {
          name: storeName,
          hash,
          status,
          currency,
          banners,
          checkoutChannel,
        } = stores[0];
        vepaarStore.storeName = storeName;
        vepaarStore.hash = hash;

        vepaarStore.checkoutChannel = checkoutChannel.channel;
        /**
         * Handle multiple store visit loggedin user
         * Set cookie expire to 7 same as apollo token
         */

        const lastStoreHash = useCookie("last-store-hash", {
          expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
        });

        const isLoggedIn = await useApolloLogin();

        if (isLoggedIn) authStore.setLoggedIn(true);
        if (isLoggedIn && lastStoreHash.value && lastStoreHash.value !== hash) {
          authStore.onLogout();
        }
        lastStoreHash.value = hash;

        vepaarStore.status = status;
        vepaarStore.currencyDecimals = currency?.decimalPlaces || 2;
        vepaarStore.currency = currency?.abbreviation;
        vepaarStore.currencySymbol =
          currency?.symbol || currency?.abbreviation || "";
        vepaarStore.banners = banners?.map((item) => {
          return {
            id: item.id,
            url: item.url,
            mediaUrl: item.media.size._700x394,
          };
        });
      }
    }
  } catch (err) {
    console.error(err);
    /**
     * Report to Discord
     */
    // if (
    //   process.env.VERCEL_ENV === 'production' &&
    //   route.query.source !== 'discord'
    // ) {
    //   // discord(err, {
    //   //   location: 'Nuxt Server Init',
    //   //   headers: req.headers,
    //   //   path: route.fullPath,
    //   //   subdomain: vepaarSubdomain,
    //   //   customDomain: customDomainInHeader,
    //   // })
    // }

    /**
     * Store Not Found error example: 'GraphQL error: store-not-found not found.'
     * Redirect to Error Page
     */
    switch (err.message) {
      case "STORE_NOT_FOUND":
        throw createError({
          statusCode: 404,
          message: "Store Not Found",
        });
      default:
        throw createError(err);
    }
  }
}

onMounted(() => {
  initCart();

  /**
   * AuthStore resets server side due to some unknown reason, therefore setting client side
   */
  useApolloLogin().then((isLoggedIn) => {
    if (isLoggedIn) {
      const { fetchCustomer } = useFetchCustomer();
      fetchCustomer();
    }
  });

  const vepaarStore = useVepaarStore();

  // Need to check onmounted since it overrides navigation server side and loads home page
  const { $vLink } = useNuxtApp();
  if (vepaarStore.status === "inactive") {
    $vLink({ name: "biz-about" });
  }
});

const initCart = () => {
  useCartStore().init();
  if (route.query.hash) {
    getCartByHash();
  } else {
    useCartStore().load();
  }
};

/**
 * Load Cart items created by store owner and share it with customer over whatsapp.
 */
const getCartByHash = () => {
  clients.default
    .query({
      query: cartByMessageHash,
      variables: {
        hash: route.query.hash,
      },
    })
    .then(async ({ data }) => {
      useCartStore().load(data.cartByMessageHash.sessionId);
    })
    .catch((error) => {
      console.error(error);
    });
};
</script>
