import { defineStore } from "pinia";

export const useVepaarStore = defineStore("vepaarStore", {
  state: () => ({
    checkoutChannel: null,
    hash: null,
    storeName: null,
    status: null,
    currencyDecimals: null,
    currencySymbol: null,
    currency: null,
    banners: null,
    mode: "light",
  }),

  getters: {
    isWhatsappCloudCheckoutChannel(state) {
      return state.checkoutChannel === "whatsapp_cloud_api" ? true : false;
    },
  },
});
