<template>
  <svg width="1em" height="1em" viewBox="0 0 15 15">
    <g fill="none">
      <path
        clipRule="evenodd"
        d="M7.5 8.495a2 2 0 0 0 2-1.999a2 2 0 0 0-4 0a2 2 0 0 0 2 1.999z"
        stroke="currentColor"
        strokeLinecap="square"
      ></path>
      <path
        clipRule="evenodd"
        d="M13.5 6.496c0 4.997-5 7.995-6 7.995s-6-2.998-6-7.995A5.999 5.999 0 0 1 7.5.5c3.313 0 6 2.685 6 5.996z"
        stroke="currentColor"
        strokeLinecap="square"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TeenyiconsPinOutline",
};
</script>
