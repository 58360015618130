<template>
  <NuxtLink :to="newRoute" v-bind="$attrs">
    <slot />
  </NuxtLink>
</template>

<script>
import { bizRoute } from "@/lib/utils";
import { useBizStore } from "@/store/index.js";
import { mapState } from "pinia";

export default {
  props: {
    to: {
      type: [Object, String],
      default: () => ({ name: "biz" }),
    },
  },

  computed: {
    ...mapState(useBizStore, ["isCustomDomain", "subdomain"]),
    newRoute() {
      return bizRoute(this.to, this.isCustomDomain, this.subdomain);
    },
  },
};
</script>
