<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path opacity=".3" d="M8 9h8v10H8z" fill="currentColor"></path>
    <path
      d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcTwotoneDelete",
};
</script>
