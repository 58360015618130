import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

export default (...args) => {
  const [state, ...cvaClasses] = args;

  return twMerge(
    ...cvaClasses.map((item) => {
      return cva(...item)(state);
    })
  );
};
