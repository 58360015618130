import defaultVariants from "./defaults";

export default [
  [
    "vayu-button",
    "inline-flex",
    "items-center",
    "user-select-none",
    "relative",
    "justify-center",
    "whitespace-nowrap",
    "focus:outline",
    "focus:outline-2",
    "focus:outline-offset-2",
    "cursor-pointer",
  ],
  {
    variants: {
      color: {},
      theme: {
        outline: ["border"],
      },
      size: {
        xs: ["h-6", "px-2", "text-xs"],
        sm: ["h-7", "px-3", "text-xs"],
        md: ["h-9", "px-4", "px-4", "text-sm"],
        lg: ["h-11", "px-5", "text-sm"],
        xl: ["h-14", "px-7", "text-base"],
      },
      shape: {
        rounded: ["rounded-md"],
        pill: ["rounded-full"],
        square: ["px-0"],
        circle: ["px-0", "rounded-full"],
        squircle: ["px-0", "rounded-md"],
      },
      justify: {
        start: ["justify-start"],
        end: ["justify-end"],
        center: ["justify-center"],
        spaceBetween: ["justify-between"],
      },
      direction: {
        row: [],
        rowReverse: ["flex-row-reverse"],
      },
      fluid: {
        true: ["w-full"],
      },
      loading: {
        true: ["cursor-wait"],
      },
      disabled: {
        true: ["cursor-not-allowed", "opacity-50"],
      },
    },

    compoundVariants: [
      //THEME:SOLID
      {
        color: "primary",
        theme: "solid",
        class: [
          "bg-primary-500",
          "hover:bg-primary-700",
          "text-white",
          "outline-primary-200",
        ],
      },
      {
        color: "secondary",
        theme: "solid",
        class: [
          "bg-secondary-500",
          "hover:bg-secondary-700",
          "text-white",
          "outline-secondary-300",
        ],
      },
      {
        color: "danger",
        theme: "solid",
        class: [
          "bg-danger-500",
          "hover:bg-danger-700",
          "text-white",
          "outline-danger-300",
        ],
      },
      {
        color: "warning",
        theme: "solid",
        class: [
          "bg-warning-500",
          "hover:bg-warning-700",
          "text-white",
          "outline-warning-300",
        ],
      },
      {
        color: "info",
        theme: "solid",
        class: [
          "bg-info-500",
          "hover:bg-info-700",
          "text-white",
          "outline-info-300",
        ],
      },
      {
        color: "success",
        theme: "solid",
        class: [
          "bg-success-500",
          "hover:bg-success-700",
          "text-white",
          "outline-success-300",
        ],
      },
      {
        color: "black",
        theme: "solid",
        class: ["bg-black", "text-white", "outline-gray-300"],
      },
      {
        color: "white",
        theme: "solid",
        class: ["bg-white", "text-black", "outline-gray-300"],
      },

      //THEME:MUTED
      {
        color: "primary",
        theme: "muted",
        class: [
          "bg-primary-50",
          "hover:bg-primary-100",
          "text-primary-500",
          "hover:text-primary-900",
          "outline-primary-100",
        ],
      },
      {
        color: "secondary",
        theme: "muted",
        class: [
          "bg-secondary-50",
          "hover:bg-secondary-100",
          "text-secondary-500",
          "hover:text-secondary-900",
          "outline-secondary-100",
        ],
      },
      {
        color: "danger",
        theme: "muted",
        class: [
          "bg-danger-50",
          "hover:bg-danger-100",
          "text-danger-500",
          "hover:text-danger-900",
          "outline-danger-100",
        ],
      },
      {
        color: "warning",
        theme: "muted",
        class: [
          "bg-warning-50",
          "hover:bg-warning-100",
          "text-warning-500",
          "hover:text-warning-900",
          "outline-warning-100",
        ],
      },
      {
        color: "info",
        theme: "muted",
        class: [
          "bg-info-50",
          "hover:bg-info-100",
          "text-info-500",
          "hover:text-info-900",
          "outline-info-100",
        ],
      },
      {
        color: "success",
        theme: "muted",
        class: [
          "bg-success-50",
          "hover:bg-success-100",
          "text-success-500",
          "hover:text-success-900",
          "outline-success-100",
        ],
      },
      {
        color: "gray",
        theme: "muted",
        class: [
          "bg-gray-100",
          "hover:bg-gray-200",
          "text-gray-500",
          "hover:text-gray-900",
          "outline-gray-100",
        ],
      },
      {
        color: "black",
        theme: "muted",
        class: ["bg-black", "text-white", "outline-gray-300"],
      },
      {
        color: "white",
        theme: "muted",
        class: ["bg-white", "text-black", "outline-gray-300"],
      },

      //THEME:OUTLINE
      {
        color: "primary",
        theme: "outline",
        class: [
          "border-primary-500",
          "text-primary-500",
          "hover:bg-primary-100",
        ],
      },
      {
        color: "secondary",
        theme: "outline",
        class: [
          "border-secondary-500",
          "text-secondary-500",
          "hover:bg-secondary-100",
        ],
      },
      {
        color: "danger",
        theme: "outline",
        class: ["border-danger-500", "text-danger-500", "hover:bg-danger-100"],
      },
      {
        color: "warning",
        theme: "outline",
        class: [
          "border-warning-500",
          "text-warning-500",
          "hover:bg-warning-100",
        ],
      },
      {
        color: "info",
        theme: "outline",
        class: ["border-info-500", "text-info-500", "hover:bg-info-100"],
      },
      {
        color: "success",
        theme: "outline",
        class: [
          "border-success-500",
          "text-success-500",
          "hover:bg-success-100",
        ],
      },
      {
        color: "black",
        theme: "outline",
        class: ["border-black", "text-black"],
      },
      {
        color: "white",
        theme: "outline",
        class: ["border-white", "text-white"],
      },

      // CIRCLE
      {
        shape: ["circle", "square", "squircle"],
        size: "xs",
        class: ["w-6"],
      },
      {
        shape: ["circle", "square", "squircle"],
        size: "sm",
        class: ["w-7"],
      },
      {
        shape: ["circle", "square", "squircle"],
        size: "md",
        class: ["w-9"],
      },
      {
        shape: ["circle", "square", "squircle"],
        size: "lg",
        class: ["w-11"],
      },
      {
        shape: ["circle", "square", "squircle"],
        size: "xl",
        class: ["w-14"],
      },
    ],

    defaultVariants,
  },
];
