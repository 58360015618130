<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      opacity=".3"
      d="M12.35 16.18l-4.53-4.53L5.3 18.7z"
      fill="currentColor"
    ></path>
    <path
      d="M2 22l14-5l-9-9l-5 14zm10.35-5.82L5.3 18.7l2.52-7.05l4.53 4.53zm2.18-3.65l5.59-5.59a1.25 1.25 0 0 1 1.77 0l.59.59l1.06-1.06l-.59-.59a2.758 2.758 0 0 0-3.89 0l-5.59 5.59l1.06 1.06zM9.47 7.47l1.06 1.06l.59-.59a2.758 2.758 0 0 0 0-3.89l-.59-.59l-1.06 1.07l.59.59c.48.48.48 1.28 0 1.76l-.59.59zm7.59 4.41l-1.59 1.59l1.06 1.06l1.59-1.59a1.25 1.25 0 0 1 1.77 0l1.61 1.61l1.06-1.06l-1.61-1.61a2.758 2.758 0 0 0-3.89 0zm-2-6l-3.59 3.59l1.06 1.06l3.59-3.59a2.758 2.758 0 0 0 0-3.89l-1.59-1.59l-1.06 1.06l1.59 1.59c.48.49.48 1.29 0 1.77z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcTwotoneCelebration",
};
</script>
