import defaultVariants from "./defaults";

export default [
  ["vayu-button__icon", "flex", "shrink-0", "justify-center", "items-center"],
  {
    variants: {
      size: {
        xs: ["size-3"],
        sm: ["size-4"],
        md: ["size-5"],
        lg: ["size-5"],
        xl: ["size-6"],
      },
      direction: {
        row: ["mr-2"],
        rowReverse: ["ml-2"],
      },
      loading: {
        true: ["animate-spin"],
      },
    },
    compoundVariants: [
      {
        shape: ["circle", "square", "squircle"],
        class: ["m-0"],
      },
    ],
    defaultVariants,
  },
];
