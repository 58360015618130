<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiCloseCircle",
};
</script>
