import vueForm from "@7span/vue-form";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vueForm, {
    errorAdapter: (err) => {
      if (err?.graphQLErrors?.[0]?.fields) {
        return err.graphQLErrors[0];
      } else {
        return { fields: [], message: err.graphQLErrors?.[0].message };
      }
    },
  });
});
