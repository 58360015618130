<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path d="M19 13H5v-2h14v2z" fill="currentColor"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiMinus",
};
</script>
