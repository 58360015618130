import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIcBaselineExitToApp, LazyIcDarkMode, LazyIcLightMode, LazyIcRoundCall, LazyIcRoundGridView, LazyIcRoundHome, LazyIcRoundPerson, LazyIcRoundSearch, LazyIcRoundViewList, LazyIcRoundViewStream, LazyIcRoundWork, LazyIcTwotoneAccountCircle, LazyIcTwotoneCategory, LazyIcTwotoneCelebration, LazyIcTwotoneCheckCircle, LazyIcTwotoneDelete, LazyIcTwotoneDomain, LazyIcTwotoneHome, LazyIcTwotoneImage, LazyIcTwotoneInfo, LazyIcTwotoneModeEdit, LazyIcTwotoneShoppingBag, LazyIcTwotoneShoppingCart, LazyIcTwotoneWarning, LazyMaterialSymbolsPinch, LazyMaterialSymbolsSwapVertRounded, LazyMdiAlertCircle, LazyMdiArrowLeft, LazyMdiArrowRight, LazyMdiCart, LazyMdiCheckboxBlankCircleOutline, LazyMdiCheckboxMarkedCircle, LazyMdiChevronDown, LazyMdiChevronRight, LazyMdiCloseCircleOutline, LazyMdiCloseCircle, LazyMdiClose, LazyMdiDeleteOutline, LazyMdiDelete, LazyMdiDomain, LazyMdiDownload, LazyMdiImage, LazyMdiInformationOutline, LazyMdiLoading, LazyMdiLoginVariant, LazyMdiMapMarkerOutline, LazyMdiMenu, LazyMdiMinus, LazyMdiOpenInNew, LazyMdiPdfOutline, LazyMdiPlayCircle, LazyMdiPlus, LazyMdiShareVariantOutline, LazyMdiStarBoxMultipleOutline, LazyMdiStarHalfFull, LazyMdiStarOutline, LazyMdiStar, LazyMdiWhatsapp, LazyNotoBrokenHeart, LazyNotoShoppingBags, LazyPhPackageDuotone, LazyRiLoader5Fill, LazyRiShoppingBag3Line, LazyTeenyiconsDiscountOutline, LazyTeenyiconsPinOutline, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["IcBaselineExitToApp", LazyIcBaselineExitToApp],
["IcDarkMode", LazyIcDarkMode],
["IcLightMode", LazyIcLightMode],
["IcRoundCall", LazyIcRoundCall],
["IcRoundGridView", LazyIcRoundGridView],
["IcRoundHome", LazyIcRoundHome],
["IcRoundPerson", LazyIcRoundPerson],
["IcRoundSearch", LazyIcRoundSearch],
["IcRoundViewList", LazyIcRoundViewList],
["IcRoundViewStream", LazyIcRoundViewStream],
["IcRoundWork", LazyIcRoundWork],
["IcTwotoneAccountCircle", LazyIcTwotoneAccountCircle],
["IcTwotoneCategory", LazyIcTwotoneCategory],
["IcTwotoneCelebration", LazyIcTwotoneCelebration],
["IcTwotoneCheckCircle", LazyIcTwotoneCheckCircle],
["IcTwotoneDelete", LazyIcTwotoneDelete],
["IcTwotoneDomain", LazyIcTwotoneDomain],
["IcTwotoneHome", LazyIcTwotoneHome],
["IcTwotoneImage", LazyIcTwotoneImage],
["IcTwotoneInfo", LazyIcTwotoneInfo],
["IcTwotoneModeEdit", LazyIcTwotoneModeEdit],
["IcTwotoneShoppingBag", LazyIcTwotoneShoppingBag],
["IcTwotoneShoppingCart", LazyIcTwotoneShoppingCart],
["IcTwotoneWarning", LazyIcTwotoneWarning],
["MaterialSymbolsPinch", LazyMaterialSymbolsPinch],
["MaterialSymbolsSwapVertRounded", LazyMaterialSymbolsSwapVertRounded],
["MdiAlertCircle", LazyMdiAlertCircle],
["MdiArrowLeft", LazyMdiArrowLeft],
["MdiArrowRight", LazyMdiArrowRight],
["MdiCart", LazyMdiCart],
["MdiCheckboxBlankCircleOutline", LazyMdiCheckboxBlankCircleOutline],
["MdiCheckboxMarkedCircle", LazyMdiCheckboxMarkedCircle],
["MdiChevronDown", LazyMdiChevronDown],
["MdiChevronRight", LazyMdiChevronRight],
["MdiCloseCircleOutline", LazyMdiCloseCircleOutline],
["MdiCloseCircle", LazyMdiCloseCircle],
["MdiClose", LazyMdiClose],
["MdiDeleteOutline", LazyMdiDeleteOutline],
["MdiDelete", LazyMdiDelete],
["MdiDomain", LazyMdiDomain],
["MdiDownload", LazyMdiDownload],
["MdiImage", LazyMdiImage],
["MdiInformationOutline", LazyMdiInformationOutline],
["MdiLoading", LazyMdiLoading],
["MdiLoginVariant", LazyMdiLoginVariant],
["MdiMapMarkerOutline", LazyMdiMapMarkerOutline],
["MdiMenu", LazyMdiMenu],
["MdiMinus", LazyMdiMinus],
["MdiOpenInNew", LazyMdiOpenInNew],
["MdiPdfOutline", LazyMdiPdfOutline],
["MdiPlayCircle", LazyMdiPlayCircle],
["MdiPlus", LazyMdiPlus],
["MdiShareVariantOutline", LazyMdiShareVariantOutline],
["MdiStarBoxMultipleOutline", LazyMdiStarBoxMultipleOutline],
["MdiStarHalfFull", LazyMdiStarHalfFull],
["MdiStarOutline", LazyMdiStarOutline],
["MdiStar", LazyMdiStar],
["MdiWhatsapp", LazyMdiWhatsapp],
["NotoBrokenHeart", LazyNotoBrokenHeart],
["NotoShoppingBags", LazyNotoShoppingBags],
["PhPackageDuotone", LazyPhPackageDuotone],
["RiLoader5Fill", LazyRiLoader5Fill],
["RiShoppingBag3Line", LazyRiShoppingBag3Line],
["TeenyiconsDiscountOutline", LazyTeenyiconsDiscountOutline],
["TeenyiconsPinOutline", LazyTeenyiconsPinOutline],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
