import { defineStore } from "pinia";

export const useBizStore = defineStore("bizStore", {
  state: () => ({
    subdomain: null,
    logo: null,
    name: null,
    desc: null,
    otherLink: null,
    socialLink: null,
    pages: null,
    address: null,
    isCustomDomain: null,
    callingCode: null,
    countryCode: null,
    cover: null,
    mobiles: null,
    language: "en",
    theme: null,
    webIntegrations: null,
    credits: null,
    customCodes: null,
    url: null,
    productPriceDisplay: null,
  }),

  getters: {
    hasCredits(state) {
      return state.credits > 0;
    },
  },
});
