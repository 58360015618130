import { defineStore } from "pinia";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user: {
      id: null,
      name: null,
      email: null,
      mobileNumber: {
        numberWithCallingCode: null,
        internationalFormat: null,
      },
    },
    isLoggedIn: false,
  }),
  getters: {
    authUserId(state) {
      return state.user.id;
    },

    isAuthUserProfileComplete(state) {
      return Boolean(state.user.name);
    },
  },
  actions: {
    setUser(user) {
      this.user.id = user.id;
      this.user.name = user.name;
      this.user.email = user.email;
      this.user.mobileNumber.numberWithCallingCode =
        user.mobileNumber?.numberWithCallingCode;
      this.user.mobileNumber.internationalFormat =
        user.mobileNumber?.internationalFormat;
    },

    setLoggedIn(value) {
      this.isLoggedIn = value;
    },

    onLogin(token) {
      const { onLogin } = useApollo();
      return onLogin(token).then(() => {
        this.isLoggedIn = true;
      });
    },

    onLogout(callback = () => {}) {
      const { onLogout } = useApollo();
      onLogout().then(() => {
        this.isLoggedIn = false;
        callback();
      });
    },

    resetUser() {
      this.user = {
        id: null,
        name: null,
        email: null,
        mobileNumber: {
          numberWithCallingCode: null,
          internationalFormat: null,
        },
      };
    },
  },
});
