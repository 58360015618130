<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M10 17l-5-5l1.41-1.42L10 14.17l7.59-7.59L19 8m-7-6A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiCheckboxMarkedCircle",
};
</script>
