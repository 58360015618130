import products from "@/graph/store/products.gql";
import orders from "@/graph/store/orders.gql";
import categories from "@/graph/store/categories.gql";
import { GET_CART } from "@/graph/store/cart/gql.js";
import addresses from "@/graph/store/addresses/collection.gql";
import reviews from "@/graph/store/review/collection.gql";

export default {
  products,
  orders,
  categories,
  GET_CART,
  addresses,
  reviews,
};
