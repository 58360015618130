import { defineStore } from "pinia";
import { useVepaarStore } from "@/store/store.js";
import { useApolloLogin } from "@/composables/useApolloLogin";

import { useMutation } from "@vue/apollo-composable";
import { UPSERT_CART, DELETE_CART, GET_CART } from "@/graph/store/cart/gql.js";
import { cloneDeep } from "lodash-es";

const cartFields = {
  addressId: null,
  addressFields: {},
  customFields: [],
  checkoutFields: {},
  sessionId: null,
};

export const useCartStore = defineStore("cartStore", {
  state: () => ({
    cart: null,
    cartFields: {},
    isLoading: true,
  }),

  getters: {
    cartProducts() {
      return this.cart.cartProducts.map((product) => {
        return {
          productId: Number(product.product.id),
          qty: product.qty,
        };
      });
    },

    sessionId() {
      const { hash } = useVepaarStore();
      return this.cartFields[hash].sessionId;
    },

    total() {
      return this.cart.total;
    },

    subTotal() {
      return this.cart.subTotal;
    },

    deliveryCharge() {
      return this.cart.deliveryCharge;
    },

    charges() {
      return this.cart.charges;
    },

    checkoutAddonCharge() {
      return this.cart.checkoutAddonCharge;
    },

    hasMinimumAmount() {
      return this.cart.hasMinimumAmount;
    },

    appliedCoupon() {
      return this.cart ? this.cart.appliedCoupon : null;
    },

    couponCode() {
      return this.appliedCoupon ? this.appliedCoupon.code : null;
    },

    isEmpty() {
      return !this.isLoading && this.cart && this.cart.cartProducts.length
        ? false
        : true;
    },

    product: (state) => {
      return (id) =>
        state.cart &&
        state.cart.cartProducts.find((item) => item.product.id == id);
    },

    shippingLocationId() {
      return this.cart && this.cart.shippingLocation
        ? this.cart.shippingLocation.id
        : null;
    },

    shippingLocationName() {
      return this.cart && this.cart.shippingLocation
        ? this.cart.shippingLocation.name
        : null;
    },

    checkoutAddons() {
      return this.cart.checkoutAddonAttributes;
    },

    checkoutAddonsId() {
      if (this.checkoutAddons.length) {
        return this.checkoutAddons.map((addon) => addon.id);
      } else return [];
    },

    addressFields() {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.addressFields;
    },

    checkoutFields() {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.checkoutFields;
    },

    customFields() {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.customFields;
    },

    checkoutField: () => (key) => {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.checkoutFields?.[key];
    },

    addressField: () => (key) => {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.addressFields?.[key];
    },

    addressId() {
      const { hash } = useVepaarStore();
      return this.cartFields[hash]?.addressId;
    },

    productCount() {
      if (!this.cart) return 0;
      else {
        return this.cart.cartProducts.reduce((count, item) => {
          return count + item.qty;
        }, 0);
      }
    },

    errors() {
      return this.cart.errors;
    },

    couponErrors() {
      return this.cart.errors?.filter((e) => e.type.startsWith("coupon"));
    },
  },

  actions: {
    init() {
      const { hash } = useVepaarStore();
      if (!this.cartFields[hash]) {
        this.cartFields[hash] = cloneDeep(cartFields);
      }
    },

    upsertCart({
      qty = 1,
      id,
      shippingLocationId,
      couponCode,
      checkoutAddonOptionId,
      resetCoupon,
    }) {
      this.isLoading = true;
      const { hash } = useVepaarStore();
      const { mutate } = useMutation(UPSERT_CART);

      const payload = {
        hash,
        shippingLocationId: shippingLocationId || this.shippingLocationId,
        couponCode: resetCoupon ? null : couponCode || this.couponCode,
        checkoutAddonOptionId,
        id: this.sessionId,
      };

      if (id) {
        payload.product = {
          productId: id,
          qty: qty,
        };
      }

      return mutate(payload)
        .then(({ data: { addToCart } }) => {
          this.cart = addToCart;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getOrderInput() {
      const { hash } = useVepaarStore();

      const _products = this.cart.cartProducts.map((product) => {
        return {
          productId: Number(product.product.id),
          qty: product.qty,
        };
      });
      const payload = {
        storeHash: hash,
        products: _products,
        checkoutAddonOptionId: this.checkoutAddonsId,
        shippingLocationId: this.shippingLocationId,
        couponCode: this.couponCode,
        customFields: this.customFields,
        ...this.checkoutFields,
      };

      /**
       * In a case where use is not logged in and continues the checkout
       * but the login with OTP is required, the auth.loggedIn will become true after OTP Verification.
       * So we need to also make sure that addressId is selected
       * And if not, user may have filled the address fields and pass that.
       */
      return useApolloLogin().then((isLoggedIn) => {
        if (isLoggedIn && this.addressId) {
          payload.addressId = this.addressId;
        } else {
          Object.assign(payload, this.addressFields);
        }
        return payload;
      });
    },

    async reset() {
      const { hash } = useVepaarStore();
      const { mutate } = useMutation(DELETE_CART);

      return mutate({
        id: this.sessionId,
        hash,
      }).then(() => {
        this.cartFields[hash].sessionId = null;
        this.load();
      });
    },

    setShippingLocation({ id }) {
      this.cart.shippingLocationId = id;
    },

    setCheckoutFields(values) {
      const { hash } = useVepaarStore();
      this.cartFields[hash].checkoutFields = values;
    },

    setAddressFields(values) {
      const { hash } = useVepaarStore();
      this.cartFields[hash].addressFields = values;
    },

    setCheckoutField({ key, value }) {
      const { hash } = useVepaarStore();
      this.cartFields[hash].checkoutFields[key] = value;
    },

    setCustomField({ id, value }) {
      if (!id) return;

      const { hash } = useVepaarStore();
      const index = this.cartFields[hash].customFields?.findIndex(
        (item) => item.id === id
      );
      if (index > -1) {
        this.cartFields[hash].customFields[index] = { value, id };
      } else {
        this.cartFields[hash].customFields.push({ value, id });
      }
    },

    removeCustomField({ id }) {
      const { hash } = useVepaarStore();
      const index = this.cartFields[hash].customFields.findIndex(
        (field) => field.id === id
      );
      this.cartFields[hash].customFields = this.cartFields[
        hash
      ].customFields.filter((_, i) => i !== index);
    },

    setAddressField({ key, value }) {
      const { hash } = useVepaarStore();
      this.cartFields[hash].addressFields[key] = value;
    },

    setAddressId(value) {
      const { hash } = useVepaarStore();
      this.cartFields[hash].addressId = value;
    },

    load(sessionId) {
      const { hash } = useVepaarStore();
      const { clients } = useApollo();
      this.isLoading = true;

      return clients.default
        .query({
          query: GET_CART,
          variables: {
            hash,
            id: sessionId || this.sessionId,
          },
          // need to hit new request after order place instead of same cache request will not provide new cart id
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          this.cart = data.cartOverview;
          this.cartFields[hash].sessionId = data.cartOverview.sessionId;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  persist: {
    pick: ["cartFields"],
    // need to declare cookies storage here because globalOptions nuxt.config has getTime issue
    storage: piniaPluginPersistedstate.cookies({
      expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    }),
  },
});
