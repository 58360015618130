<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 128 128"
  >
    <g fill="#F44336">
      <path
        d="M93.96 8.96c-10.01 0-17.79 6.18-22.76 13.12c-5.57 7.77-11.05 20.8-13.12 25.96c-.54 1.35-.28 2.88.67 3.98L72.4 67.87c.43.5.49 1.21.17 1.78l-11.4 19.69c-.43.75-.54 1.63-.31 2.46l6.26 22.43c.28.99 1.46 1.38 2.27.75c13.33-10.44 47.44-39.08 53.04-63.02c6.98-29.86-10.56-43-28.47-43z"
      ></path>
      <path
        d="M63.55 68.35L48.94 51.37l10.93-23.2c.79-1.68.65-3.67-.42-5.19C54.97 16.62 46.9 8.96 33.99 8.96c-16.58 0-35.48 13.14-28.5 43.01c5.39 23.06 37.71 51.36 52.02 62.68c.36.29.9.05.93-.42l.61-7.11l-6.09-17.57l10.83-18.74c.45-.78.36-1.77-.24-2.46z"
      ></path>
    </g>
    <path
      fill="#C33"
      d="M93.96 8.96c-6.63 0-12.27 2.72-16.77 6.56c.81-.59 6.11-4.33 12.05-4.33c16.21 0 30.72 12.29 24.17 40.7c-5.24 22.72-34.09 49.96-46.12 62.7c.43.71 1.41.94 2.11.39c13.33-10.44 47.44-39.08 53.04-63.02c6.97-29.86-10.57-43-28.48-43z"
    ></path>
    <path
      fill="#FF8A80"
      d="M77.13 34.65c-1.76 0-3-1.7-2.36-3.34c1.19-3.02 2.73-5.94 4.58-8.54c2.74-3.84 7.95-6.08 11.25-3.75c3.38 2.38 2.94 7.14.57 9.44c-5.1 4.93-11.51 6.19-14.04 6.19zM17 24.82c3.75-4.68 10.45-8.55 16.13-4.09c3.07 2.41 1.73 7.35-1.02 9.43c-4 3.04-7.48 4.87-9.92 9.63c-1.46 2.86-2.34 5.99-2.79 9.18c-.18 1.26-1.83 1.57-2.45.46c-4.22-7.49-5.42-17.79.05-24.61z"
    ></path>
    <path
      fill="#C33"
      d="m52.96 89.55l10.83-18.74c.46-.79.36-1.78-.23-2.47L48.94 51.37L60.59 27.1c.79-1.68.65-3.67-.42-5.19C55.69 15.55 46.9 8.96 33.99 8.96c-10.02 0-20.89 4.81-26.38 15.22c5.92-7.45 15.06-10.94 23.59-10.94c12.91 0 20.98 7.66 25.46 14.02c1.07 1.52 1.21 3.51.42 5.19l-10.93 23.2l14.62 16.98c.59.69.69 1.68.23 2.47L50.17 93.83s5.91 15.8 8.1 20.7c0 0 .17 0 .3-.85c0 0 .88-6.25.4-7.74c-.45-1.42-6.01-16.39-6.01-16.39z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "NotoBrokenHeart",
};
</script>
