<template>
  <component
    :is="theTag"
    :class="buttonClassList"
    :disabled="disabled"
    :href="href"
    :to="to"
    :type="type"
  >
    <!-- Icon -->
    <!-- @slot Render your own icon. -->
    <slot name="icon">
      <VyIcon
        v-if="icon"
        :class="buttonIconClassList"
        :name="loading ? 'RiLoader5Fill' : icon"
      />
    </slot>

    <!-- Loader when there is no icon -->
    <!-- @slot Render your own icon. -->
    <slot name="loading-icon">
      <VyLoader v-if="!icon && loading" :class="buttonLoaderClassList" />
    </slot>

    <!-- Label -->
    <span v-if="label || $slots.default" :class="buttonLabelClassList">
      <!-- @slot By default label is rendered here. -->
      <slot>{{ label }}</slot>
    </span>
  </component>
</template>

<script setup>
import { computed } from "vue";
import VyIcon from "../icon/index.vue";
import { button, buttonIcon, buttonLoader, buttonLabel } from "./cva/";
import classGen from "../utils/class-gen.js";
const vLink = resolveComponent("VLink");

const props = defineProps({
  as: {
    type: String,
    default: "button",
  },
  loading: {
    type: Boolean,
  },
  type: {
    type: String,
    default: "button",
  },
  label: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  to: {
    type: [Object, String],
    default: null,
  },
  href: {
    type: String,
    default: null,
  },
  disabled: Boolean,
  color: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  shape: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
  justify: {
    type: String,
    default: null,
  },
  direction: {
    type: String,
    default: null,
  },
  fluid: {
    type: Boolean,
  },
});

const state = computed(() => {
  return {
    color: props.color,
    size: props.size,
    shape: props.shape,
    theme: props.theme,
    justify: props.justify,
    direction: props.direction,
    fluid: props.fluid,
    loading: props.loading,
    disabled: props.disabled,
  };
});

const buttonClassList = computed(() => classGen(state.value, button));
const buttonIconClassList = computed(() => classGen(state.value, buttonIcon));
const buttonLoaderClassList = computed(() =>
  classGen(state.value, buttonIcon, buttonLoader)
);

/**
 * The label should be hidden only when there is no icon.
 */
const buttonLabelClassList = computed(() =>
  classGen(
    {
      loading: props.icon ? false : props.loading,
    },
    buttonLabel
  )
);

const theTag = computed(() => {
  if (props.href) {
    return "a";
  } else if (props.to) {
    return vLink;
  } else if (props.as) {
    return props.as;
  } else {
    return "button";
  }
});
</script>
