<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      opacity=".3"
      d="M12 11h2v2h-2v2h2v2h-2v2h8V9h-8v2zm4 0h2v2h-2v-2zm0 4h2v2h-2v-2z"
      fill="currentColor"
    ></path>
    <path
      d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-4-8h2v2h-2zm0 4h2v2h-2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcTwotoneDomain",
};
</script>
