import { useAuthStore } from "@/store/auth.js"; // Adjust the path as needed
import customerGQL from "@/graph/customer/get.gql";

export const useFetchCustomer = () => {
  const authStore = useAuthStore();
  const { clients } = useApollo();

  const fetchCustomer = () => {
    return clients.default
      .query({
        query: customerGQL,
      })
      .then(({ data }) => {
        if (data.customer) {
          authStore.setUser(data.customer);
        } else {
          throw new Error("User not found");
        }
      })
      .catch((err) => {
        const graphQLError = err.graphQLErrors?.[0]?.message || "Unknown error";
        authStore.resetUser();
        authStore.onLogout();
        console.error("GraphQL Error:", graphQLError, err);
      });
  };

  return {
    fetchCustomer,
  };
};
