import { format } from "date-fns";

function injectScript(src, attributes) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;

    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        script.setAttribute(key, value);
      }
    }
    script.addEventListener("load", resolve);
    script.addEventListener("error", (e) => reject(e.error));
    document.head.appendChild(script);
  });
}

function bizRoute(route, isCustomDomain, subdomain) {
  let name, params;

  if (isCustomDomain) {
    const arr = route.name.split("-");

    arr.splice(0, 1);
    name = arr.join("-") || "index";

  } else {
    name = route.name;
  }

  if (isCustomDomain) {
    params = route.params;
  } else {
    params = {
      ...(route.params || {}),
      biz: subdomain,
    };
  }

  return {
    ...route,
    name,
    params,
  };
}

const dateFormatted = (value) => {
  return format(value * 1000, "dd/MM/yyyy hh:mm:ss a");
};

const getSelectedOptionsLabel = (field) => {
  const value = typeof field.value === "string" ? [field.value] : field.value;
  const selectedOptions = field.options
    .map((opt) => value.includes(opt.id) && opt.label)
    .filter((t) => t);
  return selectedOptions.join(", ");
};

export { injectScript, bizRoute, dateFormatted, getSelectedOptionsLabel };
