<template>
  <svg width="1em" height="1em" viewBox="0 0 256 256">
    <path
      fill="currentColor"
      d="M33.2 74.6a8.1 8.1 0 0 0-1.1 4.1v98.6a8.1 8.1 0 0 0 4.1 7l88 49.5a7.8 7.8 0 0 0 3.9 1l1-106.8l-95.9-53.4Z"
      opacity=".2"
    ></path>
    <path
      fill="currentColor"
      d="M230 70.8h-.1a16.7 16.7 0 0 0-5.9-5.9l-88-49.7a16.2 16.2 0 0 0-15.7 0l-88 49.5a16.2 16.2 0 0 0-6 5.9a.1.1 0 0 1-.1.1v.2a15 15 0 0 0-2.1 7.8v98.6a16.1 16.1 0 0 0 8.2 14l88 49.5a16.5 16.5 0 0 0 7.2 2h1.4a16.5 16.5 0 0 0 7.1-2l88-49.5a16.2 16.2 0 0 0 8.1-14V78.7a15.6 15.6 0 0 0-2.1-7.9ZM128.1 29.2L207.9 74l-30.7 17.4l-80.6-44.5Zm.9 89.6L48.5 74l31.7-17.8l80.7 44.5ZM40.1 87.6l80.9 45.1l-.8 89.7l-80.1-45.1Zm96.1 134.7l.8-89.6l32.1-18.3v38.1a8 8 0 0 0 16 0v-47.2l31-17.6v89.6Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "PhPackageDuotone",
};
</script>
