import { defineRule, configure } from "vee-validate";
import { required, email } from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";

export default defineNuxtPlugin(() => {
  defineRule("required", required);

  defineRule("email", email);

  configure({
    // Generates an English message locale generator
    generateMessage: localize("en", {
      messages: {
        required: "This field is required",
        email: "This field must be a valid email",
      },
    }),
  });
});
