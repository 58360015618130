<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "RiLoader5Fill",
};
</script>
