import vueList from "@7span/vue-list";
import queries from "../assets/queries";
import sortOptions from "../assets/sort-options";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(vueList, {
    requestHandler(requestData) {
      const { endpoint, pagination, search, params, filters, sort, payload } =
        requestData;

      const fetchPolicy = () => {
        if (payload && payload.noCache) return "network-only";
        else return "cache-first";
      };

      const { page, perPage } = pagination;

      const sortValue = sortOptions.find(
        (item) => item.value === sort.by
      )?.values;

      const query = gql`
        ${queries[endpoint]}
      `;

      const { clients } = useApollo();

      return clients.default
        .query({
          query,
          variables: {
            page,
            perPage,
            search,
            filters,
            sort: sortValue,
            ...(params?.variables || {}),
          },
          fetchPolicy: fetchPolicy(),
        })
        .then((res) => {
          if (params.result) {
            return params.result(res);
          } else {
            const keys = Object.keys(res.data);
            const queryRes = res.data[keys[0]];
            return {
              items: queryRes ? queryRes.data : [],
              count: queryRes ? queryRes.pagination.total : 0,
              res: res.data,
            };
          }
        });
    },
  });
});
