<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcRoundHome",
};
</script>
