<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7z" fill="currentColor"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiDownload",
};
</script>
