<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M4 6v14h14v2H3c-.6 0-1-.4-1-1V6h2m8.1 4.6l-1.9-1.8l2.6-.4L14 6l1.2 2.4l2.6.4l-1.9 1.9l.4 2.6L14 12l-2.4 1.2l.5-2.6M8 2h12a2 2 0 0 1 2 2v12c0 1.11-.89 2-2 2H8a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2m0 2v12h12V4H8Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiStarBoxMultipleOutline",
};
</script>
