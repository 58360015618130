import defaultVariants from "./defaults";

export default [
  ["vayu-button__label"],
  {
    variants: {
      loading: {
        true: ["opacity-0"],
      },
    },
    defaultVariants,
  },
];
