export default [
  ["vayu-icon"],
  {
    variants: {
      size: {
        xs: "h-4 w-4",
        sm: "h-6 w-6",
        md: "h-10 w-10",
        lg: "h-14 w-14",
      },
    },
  },
];
