import gql from "graphql-tag";

const CART = gql`
  fragment cart on Cart {
    sessionId
    total
    subTotal
    deliveryCharge
    hasMinimumAmount
    charges

    appliedCoupon {
      code
      discountType
      discountValue
      discountedAmount
    }

    shippingLocation {
      id
      name
    }
    errors {
      message
      type
    }
    checkoutAddonCharge
    checkoutAddonAttributes {
      id
    }
    cartProducts {
      qty
      subTotal
      regularSubTotal
      total
      charges {
        name
        amount
        total
        type
        value
      }
      message
      product {
        id
        type
        name
        isOutOfStock
        manageInventory
        measurementWithUnit
        allowQty
        price
        regularPrice
        media {
          url
          mimetype
          size {
            _200x200
          }
        }
        variant {
          attributes {
            attribute {
              name
              uiElement
            }
            option {
              label
              value
            }
          }
        }
        charges {
          name
          type
          value
        }
        stock
      }
    }
  }
`;

export const UPSERT_CART = gql`
  mutation addToCart(
    $id: String
    $hash: String!
    $product: CartProductInput
    $shippingLocationId: Int
    $checkoutAddonOptionId: [Int]
    $couponCode: String
  ) {
    addToCart(
      id: $id
      hash: $hash
      product: $product
      shippingLocationId: $shippingLocationId
      checkoutAddonOptionId: $checkoutAddonOptionId
      couponCode: $couponCode
    ) {
      ...cart
    }
  }
  ${CART}
`;

export const GET_CART = gql`
  query cartOverview($id: String, $hash: String!) {
    cartOverview(id: $id, hash: $hash) {
      ...cart
    }
  }
  ${CART}
`;

export const DELETE_CART = gql`
  mutation deleteCart($hash: String!, $id: String) {
    deleteCart(hash: $hash, id: $id)
  }
`;

export const CHECKOUT_SETTINGS = gql`
  query checkout($hash: String!) {
    checkout(hash: $hash) {
      minimumOrder {
        amount
        note
      }
      settings {
        note
      }
    }
  }
`;
