<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24" class="arrow-right">
    <path
      d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11H4z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiArrowRight",
};
</script>

<style scoped>
/* Styles for RTL languages */
:dir(rtl) .arrow-right {
  transform: scaleX(-1) translateY(1px); /* Flips horizontally and moves down */
}

/* Styles for LTR languages (default) */
:dir(ltr) .arrow-right {
  transform: translateY(1px); /* Moves down by 1 pixels without flipping */
}
</style>
