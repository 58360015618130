import { bizRoute } from "@/lib/utils";

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      vLink: (route, redirect = true) => {
        try {

          const { isCustomDomain, subdomain } = nuxtApp.payload.pinia.bizStore;

          const newRoute = bizRoute(route, isCustomDomain, subdomain);

          if (redirect) {
            const router = useRouter();
            router.push(newRoute);
          }
          return newRoute;
        } catch (error) {
          console.error("vLink Provide", error);
        }
      },
    },
  };
});
