<template>
  <VyIcon name="RiLoader5Fill" :class="loaderClassList" />
</template>

<script setup>
import classGen from "../utils/class-gen.js";
import { loader } from "./cva/";

const loaderClassList = classGen({}, loader);
</script>
