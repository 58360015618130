<template>
  <div class="flex flex-col items-center justify-center min-h-screen error">
    <img class="w-48" src="/img/down.svg" alt="" />
    <p class="mt-4 text-4xl font-bold leading-none font-display">
      {{ error.statusCode }}
    </p>
    <div
      class="pt-2 mt-2 text-xs leading-snug text-center border-t border-primary-500"
    >
      <p>Hope you can understand this!</p>
      <pre>{{ error }}</pre>
    </div>

    <VyButton
      v-if="error.statusCode === 404"
      color="warning"
      size="lg"
      shape="rounded"
      theme="solid"
      class="mt-3 rounded"
      label="Create Your Own Store"
      href="https://bit.ly/3QkHpwh"
      target="_blank"
    />

    <VyButton
      color="warning"
      size="lg"
      shape="rounded"
      theme="solid"
      class="mt-3 rounded"
      label="Refresh"
      @click="clearAppStorage"
    />
  </div>
</template>

<script setup>
defineProps({
  error: {
    type: Object,
    default: () => {},
  },
});

function clearAppStorage() {
  // Clear localStorage and sessionStorage
  localStorage.clear();
  sessionStorage.clear();

  caches.keys().then((list) => list.map((key) => caches.delete(key)));

  // Optionally reload the page if needed
  window.location.reload();
}
</script>

<style>
.error {
  background-color: #e5e5f7;
  opacity: 1;
  background-image: radial-gradient(#304ffe 0.5px, #e5e5f7 0.5px);
  background-size: 10px 10px;
}
</style>
