<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M6.5 2h11a1 1 0 0 1 .8.4L21 6v15a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6l2.7-3.6a1 1 0 0 1 .8-.4zM19 8H5v12h14V8zm-.5-2L17 4H7L5.5 6h13zM9 10v2a3 3 0 0 0 6 0v-2h2v2a5 5 0 0 1-10 0v-2h2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "RiShoppingBag3Line",
};
</script>
