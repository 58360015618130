<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M12 4V2A10 10 0 0 0 2 12h2a8 8 0 0 1 8-8z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiLoading",
};
</script>
