
// @ts-nocheck


export const localeCodes =  [
  "en",
  "hi",
  "es",
  "fr",
  "he",
  "pt",
  "ar",
  "it",
  "idn",
  "ru",
  "tr",
  "de",
  "af",
  "ro",
  "da"
]

export const localeLoaders = {
  "en": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "hi": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "es": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "fr": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "he": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "pt": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "ar": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "it": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "idn": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "ru": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "tr": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "de": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "af": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "ro": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }],
  "da": [{ key: "../lang/lang.js", load: () => import("../lang/lang.js?hash=aa50c364&locale=en" /* webpackChunkName: "locale__vercel_path0_lang_lang_js" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "i18n_config_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "English",
      "en_name": "English",
      "emoji": "🇺🇸",
      "code": "en",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "हिन्दी",
      "en_name": "Hindi",
      "emoji": "🇮🇳",
      "code": "hi",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Español",
      "en_name": "Spanish",
      "emoji": "🇪🇸",
      "code": "es",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Français",
      "en_name": "French",
      "emoji": "🇫🇷",
      "code": "fr",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "עברית",
      "en_name": "Hebrew",
      "emoji": "🇮🇱",
      "code": "he",
      "dir": "rtl",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Português",
      "en_name": "Portuguese",
      "emoji": "🇵🇹",
      "code": "pt",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "عربى",
      "en_name": "Arabic",
      "emoji": "🇦🇪 🇸🇦",
      "code": "ar",
      "dir": "rtl",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Italiano",
      "en_name": "Italian",
      "emoji": "🇮🇹",
      "code": "it",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Indonésien",
      "en_name": "Indonesian Bahasa",
      "emoji": "🇮🇩",
      "code": "idn",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Русский",
      "en_name": "Russian",
      "emoji": "🇷🇺",
      "code": "ru",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Türk",
      "en_name": "Turkish",
      "emoji": "🇹🇷",
      "code": "tr",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Deutsch",
      "en_name": "German",
      "emoji": "🇩🇪",
      "code": "de",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "دری",
      "en_name": "Dari",
      "emoji": "🇦🇫",
      "code": "af",
      "dir": "rtl",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "Română",
      "en_name": "Romanian",
      "emoji": "🇷🇴",
      "code": "ro",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    },
    {
      "name": "dansk",
      "en_name": "Danish",
      "emoji": "🇩🇰",
      "code": "da",
      "dir": "ltr",
      "files": [
        "/vercel/path0/lang/lang.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "fallbackLocale": "en",
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "en_name": "English",
    "emoji": "🇺🇸",
    "code": "en",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "हिन्दी",
    "en_name": "Hindi",
    "emoji": "🇮🇳",
    "code": "hi",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Español",
    "en_name": "Spanish",
    "emoji": "🇪🇸",
    "code": "es",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Français",
    "en_name": "French",
    "emoji": "🇫🇷",
    "code": "fr",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "עברית",
    "en_name": "Hebrew",
    "emoji": "🇮🇱",
    "code": "he",
    "dir": "rtl",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Português",
    "en_name": "Portuguese",
    "emoji": "🇵🇹",
    "code": "pt",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "عربى",
    "en_name": "Arabic",
    "emoji": "🇦🇪 🇸🇦",
    "code": "ar",
    "dir": "rtl",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Italiano",
    "en_name": "Italian",
    "emoji": "🇮🇹",
    "code": "it",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Indonésien",
    "en_name": "Indonesian Bahasa",
    "emoji": "🇮🇩",
    "code": "idn",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Русский",
    "en_name": "Russian",
    "emoji": "🇷🇺",
    "code": "ru",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Türk",
    "en_name": "Turkish",
    "emoji": "🇹🇷",
    "code": "tr",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Deutsch",
    "en_name": "German",
    "emoji": "🇩🇪",
    "code": "de",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "دری",
    "en_name": "Dari",
    "emoji": "🇦🇫",
    "code": "af",
    "dir": "rtl",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "Română",
    "en_name": "Romanian",
    "emoji": "🇷🇴",
    "code": "ro",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  },
  {
    "name": "dansk",
    "en_name": "Danish",
    "emoji": "🇩🇰",
    "code": "da",
    "dir": "ltr",
    "files": [
      {
        "path": "/vercel/path0/lang/lang.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
