<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="currentColor"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiPlus",
};
</script>
