import revive_payload_client_aGZK7TnMsZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Wu9Aym2rDY from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PlvuRQdo8O from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vXjLxaF2nF from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.4_rollup@4.28.0_vite@5.4.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_qe3Y2Gn4qk from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vHlPW3J8Jz from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DaEtCUBNH4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Yy6j1oKiFE from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3gfeEOxSv3 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.28.0_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_MtWDDfg9i3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@types+node@20.17.9_eslint@9.16.0_rollup@4.28.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3c2TgTyhEw from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.14.1592_rollup@4.28.0_vue@3.5.13/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_UIIvka2Hez from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@4.28.0_vue@3.5.13/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_client_MqOAdDMcsP from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.28.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_aYvYF44GXU from "/vercel/path0/node_modules/.pnpm/nuxt-jsonld@2.1.0_typescript@5.7.2/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_cm8R2dJo29 from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_rollup@4.28.0_vite@5.4.11_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_fJd6FiIoci from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.5.5_rollup@4.28.0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_8xqlu9X3GG from "/vercel/path0/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_rollup@4.28.0_typescript@5.7.2_vue@3.5.13/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_LrlhQFlsPh from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.28.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2Be5fZc12t from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.28.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_error_YtKBBFnBCR from "/vercel/path0/plugins/apollo-error.js";
import event_bus_JNcXIfrhYt from "/vercel/path0/plugins/event-bus.js";
import index_OL0QQUVtIF from "/vercel/path0/plugins/index.js";
import vee_validate_K3WwmJMPDb from "/vercel/path0/plugins/vee-validate.js";
import vepaar_PC3Tee6wr2 from "/vercel/path0/plugins/vepaar.js";
import vue_apollo_wk8FzSyg8Y from "/vercel/path0/plugins/vue-apollo.js";
import vue_form_a2090Pb4io from "/vercel/path0/plugins/vue-form.js";
import vue_list_PgWy3ktHDF from "/vercel/path0/plugins/vue-list.js";
export default [
  revive_payload_client_aGZK7TnMsZ,
  unhead_Wu9Aym2rDY,
  router_PlvuRQdo8O,
  _0_siteConfig_vXjLxaF2nF,
  payload_client_qe3Y2Gn4qk,
  navigation_repaint_client_vHlPW3J8Jz,
  check_outdated_build_client_DaEtCUBNH4,
  chunk_reload_client_Yy6j1oKiFE,
  plugin_vue3_3gfeEOxSv3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MtWDDfg9i3,
  plugin_3c2TgTyhEw,
  plugin_client_UIIvka2Hez,
  plugin_client_MqOAdDMcsP,
  plugin_aYvYF44GXU,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_cm8R2dJo29,
  plugin_fJd6FiIoci,
  plugin_8xqlu9X3GG,
  switch_locale_path_ssr_LrlhQFlsPh,
  i18n_2Be5fZc12t,
  apollo_error_YtKBBFnBCR,
  event_bus_JNcXIfrhYt,
  index_OL0QQUVtIF,
  vee_validate_K3WwmJMPDb,
  vepaar_PC3Tee6wr2,
  vue_apollo_wk8FzSyg8Y,
  vue_form_a2090Pb4io,
  vue_list_PgWy3ktHDF
]