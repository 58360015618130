<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3m-2 16H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7h-2v7z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiOpenInNew",
};
</script>
