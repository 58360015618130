export default [
  {
    id: 0,
    label: "recommended",
    value: null,
    values: undefined,
  },
  {
    id: 1,
    label: "newest_first",
    value: "CREATED_AT_DESC",
    values: {
      by: "created_at",
      order: "desc",
    },
  },
  {
    id: 2,
    label: "oldest_first",
    value: "CREATED_AT_ASC",
    values: {
      by: "created_at",
      order: "asc",
    },
  },
  {
    id: 3,
    label: "product_sort_price_low_to_high",
    value: "PRICE_ASC",
    values: {
      by: "price",
      order: "asc",
    },
  },
  {
    id: 4,
    label: "product_sort_price_high_to_low",
    value: "PRICE_DESC",
    values: {
      by: "price",
      order: "desc",
    },
  },
  {
    id: 5,
    label: "product_sort_name_a_to_z",
    value: "NAME_ASC",
    values: {
      by: "name",
      order: "asc",
    },
  },
  {
    id: 6,
    label: "product_sort_name_z_to_a",
    value: "NAME_DESC",
    values: {
      by: "name",
      order: "desc",
    },
  },
  {
    id: 7,
    label: "discount",
    value: "DISCOUNT_DESC",
    values: {
      by: "discount",
      order: "desc",
    },
  },
  {
    id: 8,
    label: "rating",
    value: "RATING_DESC",
    values: {
      by: "ratings",
      order: "desc",
    },
  },
];
