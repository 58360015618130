<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      opacity=".3"
      d="M4.47 19h15.06L12 5.99L4.47 19zM13 18h-2v-2h2v2zm0-4h-2v-4h2v4z"
      fill="currentColor"
    ></path>
    <path
      d="M1 21h22L12 2L1 21zm3.47-2L12 5.99L19.53 19H4.47zM11 16h2v2h-2zm0-6h2v4h-2z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IcTwotoneWarning",
};
</script>
