import { default as aboutDO9fZGRBCiMeta } from "/vercel/path0/pages/[biz]/about.vue?macro=true";
import { default as indexvlR8Wg0vPDMeta } from "/vercel/path0/pages/[biz]/account/index.vue?macro=true";
import { default as indexL4a9a2IH0KMeta } from "/vercel/path0/pages/[biz]/account/addresses/index.vue?macro=true";
import { default as indexluUhBUT2F1Meta } from "/vercel/path0/pages/[biz]/account/orders/index.vue?macro=true";
import { default as profileIxY8i0iyZiMeta } from "/vercel/path0/pages/[biz]/account/profile.vue?macro=true";
import { default as indexDQOPwmkWbkMeta } from "/vercel/path0/pages/[biz]/account/orders/[hash]/index.vue?macro=true";
import { default as _91productId_93MONWwnG2reMeta } from "/vercel/path0/pages/[biz]/account/orders/[hash]/[productId].vue?macro=true";
import { default as accountPNAcotOD4xMeta } from "/vercel/path0/pages/[biz]/account.vue?macro=true";
import { default as indexkefMckzEoiMeta } from "/vercel/path0/pages/[biz]/cart/index.vue?macro=true";
import { default as index9gEJObx4TTMeta } from "/vercel/path0/pages/[biz]/categories/index.vue?macro=true";
import { default as debugKo14RoJC3GMeta } from "/vercel/path0/pages/[biz]/debug.vue?macro=true";
import { default as login1Gwg97rnuhMeta } from "/vercel/path0/pages/[biz]/login.vue?macro=true";
import { default as indexR9lVd84sEfMeta } from "/vercel/path0/pages/[biz]/products/index.vue?macro=true";
import { default as addressfDmTDsThJFMeta } from "/vercel/path0/pages/[biz]/cart/address.vue?macro=true";
import { default as payment4rpWMgZGxMMeta } from "/vercel/path0/pages/[biz]/cart/payment.vue?macro=true";
import { default as whatsapprvsnTfzJ0QMeta } from "/vercel/path0/pages/[biz]/cart/whatsapp.vue?macro=true";
import { default as indexxEGf6gJPjlMeta } from "/vercel/path0/pages/[biz]/index.vue?macro=true";
import { default as _91slug_93uwcW6GPvsiMeta } from "/vercel/path0/pages/[biz]/categories/[slug].vue?macro=true";
import { default as _91hash_93Zed7EU6FCbMeta } from "/vercel/path0/pages/[biz]/orders/[hash].vue?macro=true";
import { default as _91slug_934rf1AgnFVTMeta } from "/vercel/path0/pages/[biz]/pages/[slug].vue?macro=true";
import { default as _91slug_93rSuZ2MvLepMeta } from "/vercel/path0/pages/[biz]/products/[slug].vue?macro=true";
import { default as _91hash_93aEUDMvhNdsMeta } from "/vercel/path0/pages/[biz]/receipt/[hash].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutDO9fZGRBCiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/about.vue")
  },
  {
    name: accountPNAcotOD4xMeta?.name,
    path: "/account",
    meta: accountPNAcotOD4xMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/account.vue"),
    children: [
  {
    name: "account",
    path: "",
    component: () => import("/vercel/path0/pages/[biz]/account/index.vue")
  },
  {
    name: "account-addresses",
    path: "addresses",
    component: () => import("/vercel/path0/pages/[biz]/account/addresses/index.vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/vercel/path0/pages/[biz]/account/orders/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/vercel/path0/pages/[biz]/account/profile.vue")
  },
  {
    name: "account-orders-hash",
    path: "orders/:hash()",
    component: () => import("/vercel/path0/pages/[biz]/account/orders/[hash]/index.vue")
  },
  {
    name: "account-orders-hash-productId",
    path: "orders/:hash()/:productId()",
    meta: _91productId_93MONWwnG2reMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/account/orders/[hash]/[productId].vue")
  }
]
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexkefMckzEoiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/index.vue")
  },
  {
    name: "categories",
    path: "/categories",
    meta: index9gEJObx4TTMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/categories/index.vue")
  },
  {
    name: "debug",
    path: "/debug",
    component: () => import("/vercel/path0/pages/[biz]/debug.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login1Gwg97rnuhMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/login.vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexR9lVd84sEfMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/products/index.vue")
  },
  {
    name: "cart-address",
    path: "/cart/address",
    meta: addressfDmTDsThJFMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/address.vue")
  },
  {
    name: "cart-payment",
    path: "/cart/payment",
    meta: payment4rpWMgZGxMMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/payment.vue")
  },
  {
    name: "cart-whatsapp",
    path: "/cart/whatsapp",
    meta: whatsapprvsnTfzJ0QMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/whatsapp.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexxEGf6gJPjlMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/index.vue")
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    meta: _91slug_93uwcW6GPvsiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/categories/[slug].vue")
  },
  {
    name: "orders-hash",
    path: "/orders/:hash()",
    meta: _91hash_93Zed7EU6FCbMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/orders/[hash].vue")
  },
  {
    name: "pages-slug",
    path: "/pages/:slug()",
    meta: _91slug_934rf1AgnFVTMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/pages/[slug].vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    meta: _91slug_93rSuZ2MvLepMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/products/[slug].vue")
  },
  {
    name: "receipt-hash",
    path: "/receipt/:hash()",
    component: () => import("/vercel/path0/pages/[biz]/receipt/[hash].vue")
  },
  {
    name: "biz",
    path: "/:biz()",
    meta: indexxEGf6gJPjlMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/index.vue")
  },
  {
    name: "biz-about",
    path: "/:biz()/about",
    meta: aboutDO9fZGRBCiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/about.vue")
  },
  {
    name: accountPNAcotOD4xMeta?.name,
    path: "/:biz()/account",
    meta: accountPNAcotOD4xMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/account.vue"),
    children: [
  {
    name: "biz-account",
    path: "",
    component: () => import("/vercel/path0/pages/[biz]/account/index.vue")
  },
  {
    name: "biz-account-addresses",
    path: "addresses",
    component: () => import("/vercel/path0/pages/[biz]/account/addresses/index.vue")
  },
  {
    name: "biz-account-orders",
    path: "orders",
    component: () => import("/vercel/path0/pages/[biz]/account/orders/index.vue")
  },
  {
    name: "biz-account-profile",
    path: "profile",
    component: () => import("/vercel/path0/pages/[biz]/account/profile.vue")
  },
  {
    name: "biz-account-orders-hash",
    path: "orders/:hash()",
    component: () => import("/vercel/path0/pages/[biz]/account/orders/[hash]/index.vue")
  },
  {
    name: "biz-account-orders-hash-productId",
    path: "orders/:hash()/:productId()",
    meta: _91productId_93MONWwnG2reMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/account/orders/[hash]/[productId].vue")
  }
]
  },
  {
    name: "biz-cart",
    path: "/:biz()/cart",
    meta: indexkefMckzEoiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/index.vue")
  },
  {
    name: "biz-categories",
    path: "/:biz()/categories",
    meta: index9gEJObx4TTMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/categories/index.vue")
  },
  {
    name: "biz-debug",
    path: "/:biz()/debug",
    component: () => import("/vercel/path0/pages/[biz]/debug.vue")
  },
  {
    name: "biz-login",
    path: "/:biz()/login",
    meta: login1Gwg97rnuhMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/login.vue")
  },
  {
    name: "biz-products",
    path: "/:biz()/products",
    meta: indexR9lVd84sEfMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/products/index.vue")
  },
  {
    name: "biz-cart-address",
    path: "/:biz()/cart/address",
    meta: addressfDmTDsThJFMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/address.vue")
  },
  {
    name: "biz-cart-payment",
    path: "/:biz()/cart/payment",
    meta: payment4rpWMgZGxMMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/payment.vue")
  },
  {
    name: "biz-cart-whatsapp",
    path: "/:biz()/cart/whatsapp",
    meta: whatsapprvsnTfzJ0QMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/cart/whatsapp.vue")
  },
  {
    name: "biz-categories-slug",
    path: "/:biz()/categories/:slug()",
    meta: _91slug_93uwcW6GPvsiMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/categories/[slug].vue")
  },
  {
    name: "biz-orders-hash",
    path: "/:biz()/orders/:hash()",
    meta: _91hash_93Zed7EU6FCbMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/orders/[hash].vue")
  },
  {
    name: "biz-pages-slug",
    path: "/:biz()/pages/:slug()",
    meta: _91slug_934rf1AgnFVTMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/pages/[slug].vue")
  },
  {
    name: "biz-products-slug",
    path: "/:biz()/products/:slug()",
    meta: _91slug_93rSuZ2MvLepMeta || {},
    component: () => import("/vercel/path0/pages/[biz]/products/[slug].vue")
  },
  {
    name: "biz-receipt-hash",
    path: "/:biz()/receipt/:hash()",
    component: () => import("/vercel/path0/pages/[biz]/receipt/[hash].vue")
  }
]