<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M1 11V6h1.5v2.45L8.45 2.5H6V1h5v5H9.5V3.55L3.55 9.5H6V11Zm13.325 12q-.45 0-.912-.163q-.463-.162-.738-.437l-4.55-4.775l.825-.85q.175-.175.413-.25q.237-.075.512-.025l3.25.75V6.5q0-.625.437-1.062Q14 5 14.625 5t1.063.438q.437.437.437 1.062v6h.9q.2 0 .45.05t.45.15l4.1 2.05q.575.275.875.863q.3.587.2 1.212l-.625 4.45q-.125.75-.675 1.237q-.55.488-1.3.488Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MaterialSymbolsPinch",
};
</script>
